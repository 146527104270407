import React, { useEffect, useRef, useState } from "react";
import Router from 'next/router'
import { Form, Input, Button, Row, Col, Carousel, Alert } from "antd";
import Layout from "antd/lib/layout/layout";
import { toastM } from "../components/Toast";
import { getEventDetails, getExhibitorLoginScreen, login, loginDelegate, loginDelegateOtp, sendOtp } from "../api/auth/login";
import Error from 'next/error';
import { asImmutable, mediaSizes, setCookies } from "../../helpers/utils";
import moment from "moment";
import Head from "next/head";
import Link from "next/link";


const Login = ({ error, errorCode, errorTitle, ...props }) => {

    const ref = useRef()

    // const _params = useSearchParams()
    // const params = {}
    // for (let entry of _params.entries()) {
    //     params[entry[0]] = entry[1]
    // }

    const [loginType, setLoginType] = useState(process.env.NEXT_API_LOGIN_TYPE) //10 delegate, 20 exhibitor
    const [otpKey, setOtpKey] = useState()
    const [isLg, setisLg] = useState()



    const [adsCount, setAdsCount] = useState(2)
    const [text] = useState(props.note)
    const [hasCarousel, setHasCarousel] = useState(text ? false : props.carousel && props.carousel.length > 0)
    // const [hasCarousel, setHasCarousel] = useState(false)
    // const [carousel, setCarousel] = useState(props.carousel)


    const handleResize = () => {
        if (props?.carousel?.length == 1) {
            setAdsCount(1)
        } else {
            if (ref.current && ref.current.clientWidth > 900) {
                setAdsCount(3)
            } else if (ref.current && ref.current.clientWidth > 600) {
                setAdsCount(2)
            } else {
                setAdsCount(1)
            }
        }
        if (typeof window !== "undefined") {
            setisLg(window.matchMedia(`(min-width: ${mediaSizes['lg']})`).matches)
        }
    }



    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize()
        return () => window.removeEventListener('resize', handleResize);
    }, [ref])

    useEffect(() => {
        Object.keys(localStorage).filter(x => x.startsWith('menu')).map(x => {
            localStorage.removeItem(x)
        })
    }, [])


    if (errorCode || errorTitle) {
        return <div className='bg-stone-300'><Error message={error} statusCode={errorCode} title={errorTitle} /></div>
    }


    const __title = 'Login'


    const onFinish = async (values) => {


        if (loginType == 20) {
            const formData = new FormData();

            Object.keys(values).forEach((element) => {
                formData.append(element, values[element]);
            });

            await fetch('/api/auth/' + props.id + '/login_otp', {
                body: JSON.stringify(values),
                method: "POST",
                headers: {
                    'Content-Type': 'application/json'
                },
            })
                .then(async (response) => {
                    if (response.ok) {
                        Router.push('/')
                    } else {
                        const res = await response.json()
                        toastM.error(asImmutable(res || {}).getIn(['message']) || e.message || 'Error Try again!')
                    }
                })
        } else {
            if (otpKey) {
                await fetch('/api/auth/' + props.id + '/login_verify', {
                    body: JSON.stringify({
                        'username': values['email'],
                        'key': otpKey,
                        'otp': values['otp'],
                        'device_type': 30,
                    }),
                    method: "POST",
                    headers: {
                        'Content-Type': 'application/json'
                    },
                })
                    .then(async (response) => {
                        if (response.ok) {
                            const maxAge = Math.round((new Date(Date.now() + 86400 * 1000) - Date.now()) / 1000);
                            setCookies("event_start_date", props.event_start_date, maxAge);
                            setCookies("event_end_date", props.event_end_date, maxAge);
                            Router.push('/')
                        } else {
                            const res = await response.json()
                            toastM.error(res?.message || 'Error')
                        }
                    })

            } else {
                const data = await loginDelegate(props.id, values['email'])
                    .then((response) => {
                        return response
                    }).catch(e => {
                        toastM.error(e?.response?.data?.message || 'Error')
                        return null
                    })

                if (data) {
                    if (data?.data?.key) {
                        setOtpKey(data?.data?.key)
                        toastM.success("OTP send successfully")
                        await sendOtp(props.id, data?.data?.key, 0).then(e => e).catch(e => console.log(e))
                    }
                }
            }

            // const response = await fetch('/api/auth/' + props.id + '/login_otp', {
            //     body: JSON.stringify(values),
            //     method: "POST",
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            // })
            //    
        }
    };








    return <>
        <Head>
            <title>{__title}</title>
        </Head>
        <Layout
            className="p-0 min-h-screen "
            style={{
                backgroundSize: "cover",
                '--bg': '#242F39',
                '--bgcontent': 'white',
                background: 'var(--bg)'
            }}
        >

            <div className="m-0 p-0 min-h-screen w-full flex flex-col lg:flex-row gap-2">
                <div ref={ref}
                    // md={12}
                    // lg={text ? 12 : 14}
                    // span={24}
                    className="__login_banner rounded-br-[32px] sm:h-auto xs:h-auto lg:min-h-screen flex flex-col gap-2"

                >

                    <div className="text-center w-full __banner_img "
                    // style={hasCarousel ? { height: isLg ? 'calc(100vh - 220px)' : 'auto' } : {}}
                    // style={hasCarousel ? { maxHeight: isLg ? 'calc(100vh - 220px)' : 'fit-content' } : {}}
                    >
                        {props.exhibitor_banners ? <a href={props.exhibitor_banners.banner_url || '#'}
                            key={props.exhibitor_banners.exhibitor_banner_id}
                        >
                            <img alt=""
                                className="m-auto object-contain"
                                src={`${props.exhibitor_banners.banner_image}`}
                            />
                        </a> : null}
                    </div>


                    {
                        text ? <>
                            <div style={{ height: '0.5rem' }} className="w-full __divider"></div>
                            <div className="w-full m-auto"
                                dangerouslySetInnerHTML={{ __html: text }}
                            />
                        </>
                            : hasCarousel && adsCount > 0 ?
                                <>
                                    <div style={{ height: '0.5rem' }} className="w-full __divider"></div>
                                    <div className="overflow-hidden flex flex-wrap justify-around items-center w-full __login_bottom_carousel"
                                        style={{ height: 'auto', padding: 10 }}>
                                        {adsCount > 1 ?
                                            [...Array(adsCount)].map((x, index) => {
                                                return <CarouselItem key={index + '_' + adsCount} {...{ index, adsCount, carousel: props.carousel }} />
                                            }) :
                                            <a href={props.carousel[0].banner_url || '#'} key={props.carousel[0].exhibitor_banner_id} className="w-full0">
                                                <img
                                                    className="rounded shadow-7xl flex-10 bg-white w-full"
                                                    alt=""
                                                    src={`${props.carousel[0].banner_image}`} />
                                            </a>
                                        }

                                    </div>
                                </> : null}


                    {/* </div> */}
                </div>
                <div
                    // md={12}
                    // lg={text ? 12 : 10}
                    // span={24}
                    className="pl-0 flex "
                // style={{ minWidth: isLg ? '40vw' : '100%' }}
                >
                    <div className='w-full flex items-center md:items-inherit' >
                        {/* px-24 md:px-12 sm:px-3 */}
                        <div
                            className="flex flex-col items-center bg-white rounded-bl-[32px] shadow justify-center items-center lg:h-full w-full p-5"
                        >
                            <div className="flex flex-col border p-3 lg:p-5">
                                <div className="w-full pl-3 md:pl-8 mt-8 lg:mt-0 text-center">
                                    <div className={`font-bold ${props.event_logo ? 'text-xl' : 'text-5xl'} mt-7 mb-3`}>{props.event_name}</div>
                                    <div className="font-bold text-lg mb-5">{
                                        `${moment(props.event_start_date).format('MMM DD')}
                                    - ${moment(props.event_end_date).format('MMM DD, YYYY')}
                                    `}</div>
                                </div>


                                <div className="flex flex-col w-full px-12 items-center"
                                    style={{ maxWidth: 600 }}>
                                    {props.event_logo ? <img
                                        alt=""
                                        src={`${props.event_logo}`}
                                        className="my-5 pb-3"
                                        style={{ maxWidth: 300, maxHeight: 400 }}
                                    /> : <></>}



                                    <Form
                                        name="basic"
                                        layout="vertical"
                                        onFinish={onFinish}
                                        className="w-full px-5 mx-2 lg:mx-8 "

                                    >
                                        {loginType == 20 ? <>
                                            <Form.Item
                                                label="Email / Phone"
                                                name="email"
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please Enter your Email/Phone!",
                                                    },
                                                ]}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Form.Item
                                                label="Password"
                                                name="password"
                                                rules={[
                                                    { required: true, message: "Please Enter Your Password!" },
                                                ]}
                                            >
                                                <Input.Password />
                                            </Form.Item>

                                            <div className="my-2 flex flex-row justify-end">
                                                <Link href={`/${props?.event_id}/reset-password`} className="__primary_color font-semibold">
                                                    Forgot Password ?
                                                </Link>
                                            </div>
                                            <div className="flex text-end w-full justify-end">
                                                <Button type="primary" htmlType="submit" className="mt-2 __btn px-4 __bt_primary border-0" style={{
                                                    backgroundColor: '#E72D20'
                                                }}>
                                                    Login
                                                </Button>
                                            </div>
                                        </> : <>
                                            <Form.Item
                                                label="Email / Phone"
                                                name="email"

                                                rules={[
                                                    {
                                                        required: true,
                                                        message: "Please Enter your Email/Phone!",
                                                    },
                                                ]}
                                            >
                                                <Input disabled={otpKey != null} placeholder="Enter your email/phone" />
                                            </Form.Item>

                                            {otpKey ?

                                                <>
                                                    <Alert message="An OTP has been sent to your email address. Please check your inbox or spam folder." type="success" className="mb-3" />
                                                    <Form.Item
                                                        label="OTP"
                                                        name="otp"
                                                        rules={[
                                                            { required: true, message: "Please Enter otp!" },
                                                        ]}
                                                    >
                                                        <Input.Password placeholder="Enter OTP" />
                                                    </Form.Item>
                                                </>
                                                : null}

                                            <div className="flex text-end w-full justify-between">
                                                {otpKey ?
                                                    <div className="mt-2 pl-2 cursor-pointer" onClick={() => setOtpKey()}>Reset</div> : <div></div>
                                                }

                                                <Button type="primary" htmlType="submit" className="mt-2 __btn px-4 __bt_primary border-0" style={{
                                                    backgroundColor: '#E72D20'
                                                }}>
                                                    {otpKey ? 'Login' : 'Get OTP'}
                                                </Button>
                                            </div>
                                        </>

                                        }
                                    </Form>


                                    {/* <Button
                                    type="primary"
                                    className="mt-5 __btn px-4 __bt_primary border-0"
                                    style={{
                                        // backgroundColor: '#E72D20'
                                    }}
                                    onClick={() => {
                                        setLoginType(loginType == 20 ? 10 : 20)
                                    }}
                                >
                                    {loginType == 20 ? 'Delegate Login' : 'Exhibitor Login'}
                                </Button> */}


                                </div>
                                <div className="mt-5 w-full">
                                    <Row gutter={[8, 8]} className="w-full flex justify-center items-center ">
                                        {props.android_url || process.env.NEXT_PUBLIC_PLAY_STORE ?
                                            <Col span={12} lg={7} className="p-2">
                                                <a href={props.android_url || process.env.NEXT_PUBLIC_PLAY_STORE}>
                                                    <img width={'100%'} src={'/images/playstore.png'} />
                                                </a>
                                            </Col> : null
                                        }
                                        {props.android_url || process.env.NEXT_PUBLIC_PLAY_STORE ?
                                            <Col span={12} lg={7} className="p-2">
                                                <a href={props.ios_url || process.env.NEXT_PUBLIC_APP_STORE} >
                                                    <img width={'100%'} src={'/images/appstore.png'} />
                                                </a>
                                            </Col>
                                            : null}
                                    </Row>
                                </div>
                            </div>
                            {props.login_note ?
                                <div className="loginNote" dangerouslySetInnerHTML={{ __html: props.login_note }}>
                                </div> : null}

                        </div>
                    </div>
                </div>
            </div >

        </Layout >
    </>
};





Login.getLayout = function getLayout(page) {
    return page
}

const CarouselItem = ({ adsCount, index, carousel }) => {

    const [items, setItems] = useState(carousel)
    useEffect(() => {
        if (carousel.length > 1) {
            if (index == 1) {
                let data = [...carousel.length == 1 ? carousel.concat(carousel) : carousel]
                let i = data.shift()
                let ii = data.concat(i)
                setItems(ii)
            } else if (index == 2) {
                let data = [...carousel.length == 1 ? carousel.concat(carousel) : carousel]
                let i = data.shift()
                let ii = data.concat(i)
                i = ii.shift()
                ii = ii.concat(i)
                setItems(ii)
            }
        }

    }, [index, adsCount, carousel])


    return <Carousel
        key={index + adsCount}
        autoplay={true}
        dots={false}
        style={{ width: 300, height: 200, alignItems: 'center' }}
    >
        {
            (items || []).map(x => {
                return <a href={x.banner_url || '#'} key={x.exhibitor_banner_id} className="w-full0">
                    <img
                        className="rounded shadow-7xl flex-10 bg-white w-full"
                        alt=""
                        src={`${x.banner_image}`} />
                </a>
            })
        }
        {/* {
            (carousel || []).map(x => {
                return <a href={x.banner_url || '#'} key={x.exhibitor_banner_id} className="w-full0">
                    <img
                        className="rounded shadow-7xl flex-10 bg-white w-full h-full"
                        alt=""
                        src={`${x.banner_image}`}
                    />
                </a>
            })
        } */}
    </Carousel>
}



export const getServerSideProps = async (context) => {
    const id = context.params.id

    const q = context.query
    var v = {}
    if (q) {
        Object.keys(q).map(x => {
            v[x] = q[x]
        })
    }

    const { res: data, ...d } = await getExhibitorLoginScreen(id, q)
        .then(x => {
            if (x.event_logo) {
                const exp = ` expires=${(new Date(Date.now() + 86400 * 1000)).toUTCString()}; path=/;`;
                context.res.setHeader('Set-Cookie', `event_logo=${x.event_logo};${exp}`)
            }
            // return { props: x }
            return ({ res: x })
        })
        .catch(e => {
            const res = e.response.status || 522
            return {
                error: `couldn't find the thing`,
                errorCode: res,
                errorTitle: 'This Event is not Found or closed for Login'
            }
        })

    if (d.errorCode) {
        if (d.errorCode == 401) {
            return {
                redirect: {
                    destination: "/logout",
                }
            }
        }
        return {
            props: d
        }
    }


    return { props: { ...data, id } };
}



export default Login;